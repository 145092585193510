import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography
} from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import CarouselCurso from '../components/CarouselCurso';
import CarouselBlog from '../components/CarouselBlog';
import Depoimentos from '../components/Depoimentos';
import Footer from '../components/Footer';
import { Icon } from '@iconify/react';

// Images
import Metodo from '../images/ronaldo.webp';
import Tipografia from '../images/tipografia.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   container: {
      position: 'relative',
   },
   relative: {
      position: 'relative',
   },
   title: {
      textAlign: `center`,
   },
   bold: {
      fontWeight: '800',
   },
   button: {
      width: '100%',
      color: theme.palette.secondary.dark,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
         color: theme.palette.primary.dark,
         backgroundColor: theme.palette.primary.main,
      },
   },
   box: {
      padding: '3rem',
      backgroundColor: 'rgba(8, 8, 8, 0.8)',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
         padding: '0',
      },
   },
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginTop: '0.1rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },
   iconPlus: {
      fontSize: '1.5rem',
      marginTop: '0.2rem',
      marginBottom: '0',
   },
   iconContact: {
      display: 'inline',
      paddingRight: '1rem',
      marginTop: '0.25rem',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      [theme.breakpoints.down('sm')]: {

      }
   },
   tagline: {
      color: theme.palette.secondary.main,
   },
   tipografia: {
      background: `url('${Tipografia}')`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      width: '100%',
      height: '375px',
      paddingTop: '2rem',
   },
   contatoBox: {
      padding: '1.25rem 2rem !important',
      fontWeight: 'bold !important',
      borderRadius: '12px',
      textAlign: 'center',
      border: '1px solid rgba(128,136,146,0.2)',
      color: theme.palette.common.white,
   },
   link: {
      color: theme.palette.common.white,
      textDecoration: 'none',
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />

         <Header />

         <Box mt={{ xs: 8, md: 10 }} px={{ xs: 4, md: 0 }} mb={{ xs: 4, md: 10 }}>
            <Container className={classes.container}>

               {/* Nossos cursos */}
               <Grid container justifyContent='flex-start' alignItems='center'>
                  <Grid item className={classes.white}>
                     <Box mb={{ xs: 0, md: 0 }} pr={{ xs: 0, md: 8 }}>
                        <Typography component='h2' variant='h2'>
                           <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                           Cursos em destaque
                        </Typography>
                     </Box>
                  </Grid>
                  {/* <Grid item> */}
                  {/* <Typography component='span' variant='body2' className={classes.tagline}> */}
                  {/* Faça parte do maior ecossistema de aprovações em carreiras policiais do Brasil. */}
                  {/* </Typography> */}
                  {/* </Grid> */}
               </Grid>
               <Box mt={{ xs: 4, md: 8 }} mb={32} px={0}>
                  <Grid container justifyContent='space-between'>
                     <CarouselCurso />
                  </Grid>
               </Box>

               {/* Nosso método de ensino */}
               <Grid container justifyContent='flex-start' alignItems='center' spacing={8}>
                  <Grid item md={6}>
                     <Box display={{ xs: 'none', md: 'block' }} ml={{ xs: 0, md: -12 }}>
                        <img src={Metodo} width='100%' alt='' />
                     </Box>
                  </Grid>
                  <Grid item md={5}>
                     <Box mt={{ xs: 72, md: 0 }} mb={{ xs: 8, md: 8 }} className={classes.white}>
                        <Typography component='h2' variant='h2'>
                           <Icon icon="fluent:trophy-28-regular" className={classes.icon} />
                           Nosso método de ensino
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                        <Typography component='div' variant='body1'>
                           <span className={classes.white}>Centenas de aulas e apostilas</span> não são capazes de construir a sua aprovação.
                           Mas quando você une todo esse conteúdo à uma metodologia única de estudos,
                           testada e valida nas principais bancas de concursos policiais do Brasil, a história é diferente.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                        <Typography component='div' variant='body1'>
                           <span className={classes.white}>O Método RB</span> é uma estrutura de estudos progressivos com materiais de apoio,
                           flashcards e mapas mentais que irão otimizar seu tempo, acelerar a absorção de todo o conteúdo e reforçar sua
                           preparação para a carreira policial dos seus sonhos.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                        <Typography component='div' variant='body1'>
                           <span className={classes.white}>Faça parte do maior ecossistema de aprovações em carreiras policiais do Brasil </span>
                           com a RB Carreiras Policiais e faça parte da próxima turma de aprovados.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                        <Typography component='div' variant='body1'>
                           <span className={classes.white}>Aprovamos mais de 70%</span> dos nossos alunos e para
                           ultrapassarmos essa marca contamos com você.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                        <Typography component='div' variant='body1' className={classes.primary}>
                           Nos vemos na sua formatura.
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={12}>
                     <Box display={{ xs: 'block', md: 'none' }} ml={{ xs: 0, md: -12 }}>
                        <img src={Metodo} width='100%' alt='' />
                     </Box>
                  </Grid>
               </Grid>

               {/* Estatísticas */}
               <Grid container className={classes.tipografia} justifyContent='space-around' alignItems='flex-start' spacing={8}>
                  <Grid item xs={6} md={2}>
                     <Box mb={2}>
                        <Icon icon="ps:plus-box" className={`${classes.icon} ${classes.iconPlus}`} />
                        <Typography component='span' variant='h3' className={classes.white}>1.700</Typography>
                     </Box>
                     <Typography component='div' variant='body2' className={classes.secondary}>Aprovados PRF</Typography>
                  </Grid>
                  <Grid item xs={6} md={2}>
                     <Box mb={2}>
                        <Icon icon="ps:plus-box" className={`${classes.icon} ${classes.iconPlus}`} />
                        <Typography component='span' variant='h3' className={classes.white}>400</Typography>
                     </Box>
                     <Typography component='div' variant='body2' className={classes.secondary}>Aprovados PF</Typography>
                  </Grid>
                  <Grid item xs={6} md={2}>
                     <Box mb={2}>
                        <Icon icon="ps:plus-box" className={`${classes.icon} ${classes.iconPlus}`} />
                        <Typography component='span' variant='h3' className={classes.white}>240</Typography>
                     </Box>
                     <Typography component='div' variant='body2' className={classes.secondary}>Aprovados DEAPSC</Typography>
                  </Grid>
                  <Grid item xs={6} md={2}>
                     <Box mb={2}>
                        <Icon icon="ps:plus-box" className={`${classes.icon} ${classes.iconPlus}`} />
                        <Typography component='span' variant='h3' className={classes.white}>70%</Typography>
                     </Box>
                     <Typography component='div' variant='body2' className={classes.secondary}>Aprovados na PMSC</Typography>
                  </Grid>
               </Grid>

            </Container>

            {/* Depoimentos */}

            <Box mt={{ xs: 16, md: -26 }}>
               <Container className={classes.container}>
                  <Grid container justifyContent='flex-start' alignItems='center' spacing={8}>
                     <Grid item className={classes.white}>
                        <Box mb={{ xs: 8, md: 0 }}>
                           <Typography component='h2' variant='h2'>
                              <Icon icon="mdi-light:comment-text" className={classes.icon} />
                              Depoimentos
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item>
                        <Typography component='span' variant='body2' className={classes.tagline}>
                           Veja o que nossos alunos falam sobre nossos cursos.
                        </Typography>
                     </Grid>
                  </Grid>
               </Container>
               <Box mt={{ xs: 4, md: 8 }} mb={8} px={0}>
                  <Grid container justifyContent='center'>
                     <Grid item xs={12} md={12}>
                        <Depoimentos />
                     </Grid>
                  </Grid>
               </Box>
            </Box>

            <Container className={classes.container}>

               {/* Últimas Notícias */}
               <Box mt={{ xs: 16, md: 32 }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                     <Grid item className={classes.white}>
                        <Box mb={{ xs: 8, md: 0 }} pr={{ xs: 0, md: 8 }}>
                           <Typography component='h2' variant='h2'>
                              <Icon icon="ion:book-outline" className={classes.icon} />
                              Últimas Notícias
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item>
                        <Typography component='span' variant='body2' className={classes.tagline}>
                           Abertura de concursos, vagas, notícias, aqui você não perde nenhum conteúdo.
                        </Typography>
                     </Grid>
                  </Grid>
                  <Box mt={{ xs: 4, md: 8 }} mb={8} px={0}>
                     <Grid container justifyContent='space-between'>
                        <CarouselBlog />
                     </Grid>
                  </Box>
               </Box>

               {/* Contatos */}
               <Box mt={36}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     <Grid item md={6}>
                        <Box pr={{ xs: 0, md: 6 }}>
                           <Grid container justifyContent='center' alignItems='center' className={classes.contatoBox}>
                              <Grid item>
                                 <Icon icon="fluent:mail-16-regular" className={classes.iconContact} />
                              </Grid>
                              <Grid item>
                                 <a href="mailto:adm@ronaldobandeira.com.br" className={classes.link}>
                                    <Typography component='span' variant='h4'>adm@ronaldobandeira.com.br</Typography>
                                 </a>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Box pr={{ xs: 0, md: 6 }}>
                           <Grid container justifyContent='center' alignItems='center' className={classes.contatoBox}>
                              <Grid item>
                                 <Icon icon="ph:phone" className={classes.iconContact} />
                              </Grid>
                              <Grid item>
                                 <a href="tel:4792529457" className={classes.link}>
                                    <Typography component='span' variant='h4'>Central de vendas: (47) 9252-9457</Typography>
                                 </a>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>

            </Container>
         </Box>

         <Box position='relative' zIndex='1'>
            <Footer />
         </Box>

      </Grid >
   );
}