import React, { useState } from 'react';
import { makeStyles, AppBar, Toolbar, Container, Grid, Box } from '@material-ui/core';
// import TopBarMobile from './TopBarMobile';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

// Função Onepage
import { animateScroll as scroll } from "react-scroll"; //SMOOTH SCROLL

//Images
import Logo from '../images/logo.webp';

// Icons
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
    }
  },
  appBarColor: {
    backgroundColor: '#000',
    // display: 'none',
    // boxShadow: '0px -2px 13px #CCC',
  },
  grow: {
    flexGrow: 1,
  },
  buttonOutlined: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '8px',
    backgroundColor: 'transparent',
    padding: '0.5rem 2.5rem',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '1rem',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
    }
  },
  buttonLabel: {
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
  },
  slide: {
    backgroundColor: '#000',
  },
  menuItem: {
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: '2rem',
    color: theme.palette.common.white,
    transitionDuration: '400ms',
    textDecoration: 'none',
    fontFamily: 'Inter, sans-serif',
    padding: '0 1rem',
    '&:focus, &:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'rgba(0,0,0,0.7)',
      '&:focus, &:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  },
  icons: {
    //paddingRight: theme.spacing(0),
    color: 'white',
    fontSize: '1rem',
    paddingLeft: '.6rem',
    paddingRight: '.6rem',
    [theme.breakpoints.down('sm')]: {
      color: 'rgba(0,0,0,0.6)',
    },
  },
  button: {
    color: 'white',
    boxShadow: 'none !important',
    borderRadius: '25px',
    textTransform: 'none',
    padding: '0.5rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '700',
    backgroundColor: 'transparent',
    '&hover': {
      opacity: '0.9',
      backgroundColor: 'linear-gradient(90deg, rgba(0,74,172,1) 0%, rgba(0,52,120,1) 100%);',
    },
  },
  icon: {
    fontSize: '1.25rem',
    margin: '0 0.3rem',
    paddingTop: '0.5rem',
    color: theme.palette.secondary.main,
  },
  iconUser: {
    float: 'left',
    marginTop: '-0.1rem !important',
    marginRight: '-0.5rem',
    color: theme.palette.common.white,  
  },
  containerMenu: {
    height: '100%',
    margin: '0',
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function TopBar(props) {

  const classes = useStyles();
  const [appbar, setAppbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 175) {
      setAppbar(true);
    } else {
      setAppbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (

    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar color='inherit' className={`${classes.appBar} ${appbar ? classes.appBarColor : ''}`}>
          <Toolbar>
            <Container>
              <Grid container justifyContent='flex-start' alignItems='space-between'>

                {/* Logo */}
                <Grid item xs={8} md={3}>
                  <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
                    <Grid item>
                      <Box textAlign='left' marginRight={4}>
                        <a href='/'><img onClick={scrollToTop} src={Logo} alt='Logo' width='175px' /></a>
                      </Box>
                    </Grid>
                    <Grid item>
                      <a className={classes.white} href='https://www.instagram.com/rbcarreiraspoliciais/' target="_blank" rel="noreferrer">
                        <Icon icon="fa-brands:instagram" className={classes.icon} />
                      </a>
                    </Grid>
                    <Grid item>
                      <a className={classes.white} href='https://facebook.com/RBCarreirasPoliciais/' target="_blank" rel="noreferrer">
                        <Icon icon="fa-brands:facebook" className={classes.icon} />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Mobile */}
                {/* <Grid item xs={4} md={1}>
                  <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }} alignItems='center' justifyContent='flex-end' flexWrap='nowrap'>
                    <TopBarMobile />
                  </Box>
                </Grid> */}

                {/* Menu de navegação direita */}
                <Grid item xs={0} md={8}>
                  <Grid container justifyContent='flex-end' alignItems='center' spacing={4} className={classes.containerMenu}>
                    <Grid item>
                      <a className={classes.menuItem} href='/' rel="noreferrer">
                        Home
                      </a>
                    </Grid>
                    {/* <Grid item>
                      <a className={classes.menuItem} href='https://www.rbcarreiraspoliciais.com.br/cursos' rel="noreferrer">
                        Cursos
                      </a>
                    </Grid> */}
                    <Grid item>
                      <a className={classes.menuItem} href='https://ronaldobandeira.com.br/login' rel="noreferrer">
                        <Icon icon="mingcute:user-4-line" className={`${classes.icon} ${classes.iconUser}`} />
                        Portal do Aluno
                      </a>
                    </Grid>
                    {/* <Grid item>
                        <a className={classes.white} href='https://ead.rbcarreiraspoliciais.com.br/cart' target="_blank" rel="noreferrer">
                          <Icon icon="heroicons-outline:shopping-cart" className={classes.icon} />
                          Carrinho
                        </a>
                      </Grid> */}
                  </Grid>
                </Grid>

              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>

  );
}