import React from 'react';
import Slider from 'react-slick';
import '../styles/slick.css';
import '../styles/slick-theme.css';
import { makeStyles, Box, Grid } from '@material-ui/core';

// Images
import Oberg from '../images/professor-oberg-2.webp';
import Flavia from '../images/professor-flavia-rita.webp';
import Leo from '../images/professor-leo-martins.webp';
import Marcelo from '../images/professor-marcelo-jardim.webp';
import Cobra from '../images/professor-cobra-2.webp';
import Sergio from '../images/professor-sergio.webp';
import Samuel from '../images/professor-samuel.webp';
import Notario from '../images/professor-notario.webp';
import Fabio from '../images/professor-fabio.webp';
import Joao from '../images/professor-joao.webp';
import Canario from '../images/professor-canario-2.webp';
import Bandeira from '../images/professor-bandeira-3.webp';
import Antonio from '../images/professor-antonio.webp';
import Angelo from '../images/professor-angelo.webp';
import Canezin from '../images/professor-canezin.webp';
import Andre from '../images/professor-andre.webp';
import Arthur from '../images/professor-arthur.webp';
import Adriane from '../images/professor-adriane.webp';
import Eleandra from '../images/professor-eleandra.webp';
import Von from '../images/professor-von.webp';
import David from '../images/professor-david.webp';


const useStyles = makeStyles((theme) => ({
   box: {
      overflow: 'hidden',
      backgroundColor: '#13151E',
      borderRadius: '8px',
      position: 'relative',
      minHeight: '490px',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      // padding: '2rem 1.5rem'
   },
   white: {
      color: theme.palette.common.white,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   img: {
      width: '100%',
      // display: 'inline !important',
      // borderRadius: '5px',
   },
   imageBg: {
      margin: '0 5px',
      height: '340px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'transparent !important',
   },
   card: {
      // borderRadius: '4px',
      // backgroundColor: '#13151E',
      // minHeight: '550px',
   },
   cardContent: {
      padding: '2rem',
      minHeight: '280px',
   },
   excerpt: {
      color: '#738292',
      paddingTop: '1rem',
   },
   boxHeader: {
      [theme.breakpoints.down('sm')]: {
         width: '100vw !important',
      },
   },
   cardHeader: {
      borderRadius: '5px',
      backgroundColor: '#13151E',
      padding: theme.spacing(12),
      minHeight: '550px',
      [theme.breakpoints.down('sm')]: {

      },
   },
   boxWrapper: {
      width: 'auto !important',
      [theme.breakpoints.down('sm')]: {
         // width: '100% !important',
      },
   },
}));

export default function Curso() {

   const classes = useStyles();
   var settings = {
      className: "slider",
      dots: false,
      arrows: true,
      lazyLoad: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      swipeToSlide: true,
      variableWidth: false,
      responsive: [
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 0,
               autoplay: true,
               autoplaySpeed: 3000,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (

      <Grid container justifyContent='center'>
         <Grid item xs={12} md={11}>
            <Slider {...settings}>

               <Box px={4} className={classes.boxWrapper}>
                  <img src={Oberg} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Leo} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Flavia} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Cobra} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Sergio} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Samuel} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Notario} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Fabio} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Joao} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Canario} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Antonio} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Marcelo} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Bandeira} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Angelo} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Canezin} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Andre} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Arthur} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Adriane} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Eleandra} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={David} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>
               <Box px={4} className={classes.boxWrapper}>
                  <img src={Von} className={classes.img} alt='Professor do RB Carreiras Policiais' />
               </Box>

            </Slider>
         </Grid>
      </Grid>
   );
}