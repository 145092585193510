import React from 'react';
import { makeStyles, Grid, Box, Container, Typography, Button } from '@material-ui/core';

import Bg1 from '../images/vitalicio-bg.webp';
import Bg2 from '../images/pertencerei-bg-2.webp';
import Slide1 from '../images/brasao-prf.webp';

// Slide para as fotos
import Slider from "react-slick";
import '../styles/slick.css';
import '../styles/slick-theme.css';

const useStyles = makeStyles((theme) => ({
   // section: {
      // maxHeight: '750px',
      // width: '100%',
      // height: '100vh',
      // position: 'relative',
      // backgroundSize: 'contain',
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center center',
      // backgroundColor: theme.palette.common.black,
      // [theme.breakpoints.down('sm')]: {
         // maxHeight: '300px',
      // },
   // },
   bg1: {
      position: 'relative',
      paddingTop: '8rem',
      width: '100%',
      height: '100vh',
      maxHeight: '720px',
      backgroundImage: `url('${Bg1}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   bg2: {
      position: 'relative',
      paddingTop: '6rem',
      width: '100%',
      height: '100vh',
      maxHeight: '720px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   typography: {
      position: 'absolute',
      left: '0',
      bottom: '0',
      width: '100%',
      height: 'auto',
      zIndex: '0',
   },
   container: {
      paddingTop: '6rem',
      position: 'relative',
      zIndex: '1',
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '25px',
      textTransform: 'none',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   icon: {
      float: 'left',
      fontSize: '2rem',
      margin: '-7px 5px 0 0',
      color: theme.palette.secondary.main,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
}));

export default function Header(props) {
   const classes = useStyles();

   // Slide na Header
   var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      dots: true,
      responsive: [
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 0,
               autoplay: true,
               autoplaySpeed: 3000,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (
      <Grid>
         {/* <Container className={classes.container}> */}
         {/* <Box py={{ xs: 4, md: 4 }}> */}
         <Slider {...settings}>

            {/* Vialício */}
            {/*<Box>
               <Grid className={classes.bg1}>
                  <Container>
                     <Grid
                        container
                        className={classes.containerHeader}
                        justifyContent='flex-start'
                        alignItems='center'
                        alignContent='center'
                     >
                        <Grid item md='4' className={classes.relative}>
                           <Box mt={{ xs: 6, md: 4 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                              <Box mb={4}>
                                 <Typography component='h1' variant='h1' className={classes.white}>
                                    <div className={classes.primary}>Até a Sua Aprovação </div>
                                    O maior Vitalício da história do RBCP!
                                 </Typography>
                              </Box>
                              <Box mb={4}>
                                 <Typography component='p' variant='body1' className={classes.white}>
                                    Acesso completo à todos os nossos conteúdos e aos maiores e mais completos planos de estudos para PRF e PF - Pertencerei e Projeto FOX!
                                 </Typography>
                              </Box>
                              <Box mb={8}>
                                 <Typography component='p' variant='body1' className={classes.white}>
                                    Cadastre-se em nosso evento e tenha acesso antecipado ao lançamento com um super desconto!
                                 </Typography>
                              </Box>
                              <Box mb={4}>
                                 <Button variant='contained' href="/vitalicio"
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Cadastrar agora
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>
                  </Container>
               </Grid>
            </Box> */}

            {/* Pertencerei */}
            <Box>
               <Grid className={classes.bg2}>
                  <Container>
                     <Grid
                        container
                        className={classes.container}
                        justifyContent='space-around'
                        alignItems='center'
                        alignContent='center'
                     >
                        <Grid item md='5' className={classes.relative}>
                           <Box mt={{ xs: 6, md: 0 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                              <Box mb={4}>
                                 <Typography component='h1' variant='h1' className={classes.white}>
                                 Projeto Pertencerei<div className={classes.primary}>Com Ronaldo Bandeira</div>
                                 </Typography>
                              </Box>
                              <Box mb={8}>
                                 <Typography component='p' variant='body1' className={classes.white}>
                                    O maior Projeto de estudos para PRF do Brasil, desenvolvido pelo Ronaldo Bandeira com cocriação de alunos da comunidade RB que apontaram as maiores dificuldades durante seus estudos.
                                 </Typography>
                              </Box>
                              <Box mb={4}>
                                 <Button variant='contained' href='/pertencerei'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Saiba mais
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>
                        <Grid item md='5'>
                           <Box mt={{ xs: 16, md: 0 }} mb={{ xs: -10, md: 0 }} pr={{ xs: 8, md: 0 }} textAlign='center' className={classes.display}>
                              <img src={Slide1} alt='RB Carreiras Policiais - Projeto Pertencerei 2025' width='auto' />
                           </Box>
                        </Grid>
                     </Grid>
                  </Container>
               </Grid>
            </Box>
         </Slider>
         {/* </Box> */}
         {/* </Container> */}
      </Grid>
   );
}