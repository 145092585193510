import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button
} from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import { Icon } from '@iconify/react';

// Images
// import Bg2 from '../images/bg-2.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#000000',
      overflowX: 'hidden',
      paddingTop: '8rem',
   },
   primary: {
      color: theme.palette.primary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   container: {
      position: 'relative',
   },
   relative: {
      position: 'relative',
   },
   title: {
      textAlign: `center`,
   },
   bold: {
      color: theme.palette.secondary.main,
   },
   button: {
      width: '100%',
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.75rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: '#0088CC',
      '&:hover': {
         opacity: '0.75',
         backgroundColor: '#0088CC',
      },
      [theme.breakpoints.down('sm')]: {
         padding: '0.75rem 1rem',
         fontSize: '0.8rem',
      }
   },
   box: {
      padding: '3rem',
      backgroundColor: 'rgba(8, 8, 8, 0.8)',
      borderRadius: '5px'
   },
   icon: {
      float: 'left',
      color: theme.palette.common.white,
      fontSize: '1.75rem',
      marginRight: '0.5rem',
      [theme.breakpoints.down('sm')]: {
      }
   },
   video: {
      borderRadius: '16px',
      [theme.breakpoints.down('sm')]: {
         width: '100%',
         height: 'auto',
      }
   },
   bg2: {
      // width: 'auto',
      // height: 'auto',
      // maxHeight: '600px',
      // backgroundImage: `url('${Bg2}')`,
      // backgroundSize: 'cover',
      // backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         // maxHeight: '100%',
      },
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />

         {/* Parabéns pela sua inscrição */}
         <Box className={classes.bg2} mt={{ xs: 18, md: 20 }} px={{ xs: 4, md: 0 }} mb={{ xs: 4, md: 10 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='center'>
                  <Grid item>
                     <iframe
                        className={classes.video}
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/2iUHyUi94us?autoplay=1" title="RB Carreiras Policiais"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                     </iframe>
                  </Grid>
               </Grid>
               <Grid container justifyContent='center' alignItems='center'>
                  <Grid item md={5} className={classes.white}>
                     <Box mt={12} mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>PARABÉNS PELA SUA INSCRIÇÃO EM NOSSO EVENTO.</span>
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           <strong>
                              Isso já mostra que você está na frente de muitos que ainda estão
                              apostando suas fichas no acaso ao invés de fazerem sua parte
                              na concretização do seu sonho.
                           </strong>
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Agora eu convido você a se inscrever no nosso canal do Telegram!
                           Por lá dividiremos algumas atualizações exclusivas e poderemos entender
                           mais sobre seus principais desafios e construiremos juntos a
                           maior turma de aprovados do Brasil.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='p' variant='body1'>Te vejo lá!
                        </Typography>
                     </Box>
                     <Button className={classes.button} variant='contained' href='https://t.me/+wVHeX19ayoYyMmJh'>
                        <Icon icon="uil:telegram" className={classes.icon} />
                        Entrar no Grupo do Telegram
                     </Button>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box position='relative' zIndex='1'>
            <Footer />
         </Box>

      </Grid >
   );
}