import React from 'react';
import {
  makeStyles, Container, Typography, Grid, Box, Link
} from '@material-ui/core';


// Icons
import { Icon } from '@iconify/react';

// Images
import Logo from '../images/logo.webp';

const useStyles = makeStyles((theme) => ({
  bg: {
    background: 'transparent',
  },
  white: {
    color: theme.palette.common.white,
  },
  hr: {
    height: '0',
    border: '1px solid rgba(236, 239, 246, 0.1)',
    margin: '2rem 0 1rem 0',
  },
  icon: {
    fontSize: '1.25rem',
    margin: '0 0.3rem',
    color: theme.palette.common.white,
  },
  link: {
    color: theme.palette.common.white,
    display: 'block',
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: '400',
    marginBottom: '0.5rem',
    fontFamily: 'Inter, sans-serif',
  },
  buttonOutlined: {
    width: '100%',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    borderRadius: '25px',
    textTransform: 'none',
    padding: '0.5rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '700',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (

    <Box id="contatos" className={classes.bg} pt={20} pb={30} px={6} textAlign='center'>
      <Container>

        <Grid container justifyContent='center' alignItems='center' alignContent='center'>

          {/* Logo */}
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <a href='/'><img src={Logo} alt='Logo' width='160px' /></a>
            </Box>
            <Box mb={12}>
              <Link className={classes.white} href='https://www.instagram.com/rbcarreiraspoliciais/' target="_blank">
                <Icon icon="fa-brands:instagram" className={classes.icon} />
              </Link>
              <Link className={classes.white} href='https://facebook.com/RBCarreirasPoliciais/' target="_blank">
                <Icon icon="fa-brands:facebook" className={classes.icon} />
              </Link>
            </Box>
            <Box mb={4}>
              <Typography variant='body2' component='span' className={classes.white}>
                2024 © RB Carreiras Policiais - Todos os direitos reservados.
              </Typography>
            </Box>
            {/* <Box mb={4}>
              <Typography variant='body2' component='span' className={classes.white}>
                <Box mr={1} component='span'>Desenvolvido por</Box>
              </Typography>
              <Link href='https://www.cwa.digital/' target='_blank' className={`${classes.white} ${classes.fontBold}`}>
                <Typography variant='body2' component='span'>
                  Cwa Digital
                </Typography>
              </Link>
            </Box> */}
          </Grid>

        </Grid>

      </Container>

    </Box>
  );
}