import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Obrigado from '../pages/Obrigado';
import Vitalicio from '../pages/Vitalicio';
import VitalicioObrigado from '../pages/Vitalicio/Obrigado';
import Pmsc from '../pages/Pmsc';
import Pcsc from '../pages/Pcsc';
import NinhoDasAguias from '../pages/NinhoDasAguias';
import Pertencerei from '../pages/Pertencerei';
import AgenteDeTransito from '../pages/AgenteDeTransito';
import Renascimento from '../pages/Renascimento';
import Dease from '../pages/Dease';
import Blog from '../pages/Blog';
import SinglePost from '../pages/SinglePost';
// import BlackFriday from '../pages/BlackFriday';

function Rotas() {
   return (
      <Router>
         <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/obrigado" exact element={<Obrigado />} />
            <Route path="/ate-a-gloriosa" exact element={<Vitalicio />} />
            <Route path="/vitalicio/obrigado" exact element={<VitalicioObrigado />} />
            <Route path="/pmsc" exact element={<Pmsc />} />
            <Route path="/pcsc" exact element={<Pcsc />} />
            <Route path="/ninho-das-aguias" exact element={<NinhoDasAguias />} />
            <Route path="/pertencerei" exact element={<Pertencerei />} />
            <Route path="/agente-de-transito" exact element={<AgenteDeTransito />} />
            <Route path="/renascimento" exact element={<Renascimento />} />
            <Route path="/dease" exact element={<Dease />} />
            <Route path='/blog' exact element={<Blog />} />
            <Route path='/blog/:slug' component={<SinglePost />} />
            {/* <Route path="/black-friday" exact element={<BlackFriday />} /> */}

            {/* <Route path='/blog/:slug' element={<SinglePost />} render={(matchProps) =>
               <SinglePost
                  {...matchProps}
                  {...this.props}
                  handleMatch={this.handleMatch}
               />
            } /> */}

         </Routes>
      </Router>
   );
}

export default Rotas;