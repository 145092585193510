import React from 'react';
import Slider from 'react-slick';
import '../styles/slick.css';
import '../styles/slick-theme.css';

import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';

// Images
import Post3 from '../images/post-3.webp';

const useStyles = makeStyles((theme) => ({
   box: {
      overflow: 'hidden',
      backgroundColor: '#13151E',
      borderRadius: '8px',
      position: 'relative',
      minHeight: '490px',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      // padding: '2rem 1.5rem'
   },
   white: {
      color: theme.palette.common.white,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   image: {
      width: '100%',
      height: '100%',
      borderRadius: '175px',
   },
   imageBg: {
      margin: '0 5px',
      height: '270px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'transparent !important',
   },
   card: {
      borderRadius: '4px',
      backgroundColor: '#13151E',
      margin: '0 1rem 0 0',
      // minHeight: '550px',
      [theme.breakpoints.down('sm')]: {
         margin: '0 0.75rem 0 0 !important',
      },
   },
   cardContent: {
      padding: '2rem',
      minHeight: '100px',
   },
   excerpt: {
      color: '#738292',
      paddingTop: '1rem',
   },
   boxHeader: {
      [theme.breakpoints.down('sm')]: {
         width: '100vw !important',
      },
   },
   cardHeader: {
      borderRadius: '5px',
      backgroundColor: '#13151E',
      padding: theme.spacing(12),
      minHeight: '270px',
      [theme.breakpoints.down('sm')]: {

      },
   },
   boxWrapper: {
      padding: '0 1.25rem 0 0',
      [theme.breakpoints.down('sm')]: {
         width: '320px !important',
         padding: '0 !important',
      },
   },
}));

export default function Curso() {

   const classes = useStyles();
   var settings = {
      className: "slider variable-width",
      dots: false,
      arrows: true,
      lazyLoad: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      variableWidth: true,
      responsive: [
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 0,
               autoplay: true,
               autoplaySpeed: 3000,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (
      <Slider {...settings}>

         <Box px={{ xs: 0, md: 8 }} className={classes.boxWrapper} style={{ width: 420 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://ead.rbcarreiraspoliciais.com.br/caso-genivaldo-mjsp-assina-acordo-que-preve-ampliacao-do-combate-ao-racismo-institucional/">
                  <CardMedia
                     component="img"
                     alt="RB Carreias Policiais"
                     image='https://ead.rbcarreiraspoliciais.com.br/src/uploads/2023/09/45780f93-52e4-4942-831b-1b8067b85416.jpeg'
                     height="254"
                     title="RB Carreias Policiais"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Caso Genivaldo: MJSP assina acordo que prevê ampliação do combate ao racismo institucional
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        Resolução dizia respeito à Ação Civil Pública promovida pela EducaAfro Brasil, em Sergipe; série de medidas serão colocadas em prática para promover Direitos Humanos
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>

         <Box px={{ xs: 0, md: 8 }} className={classes.boxWrapper} style={{ width: 420 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://ead.rbcarreiraspoliciais.com.br/pf-e-prf-querem-concursos-anualmente-diz-ministro-flavio-dino/">
                  <CardMedia
                     component="img"
                     alt="RB Carreias Policiais"
                     height="254"
                     image='https://ead.rbcarreiraspoliciais.com.br/src/uploads/2023/09/ministro-flavio-dino-foto-lula-marques-agencia-brasil.webp'
                     title="RB Carreias Policiais"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        PF e PRF querem concursos anualmente, diz ministro Flávio Dino
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        O ministro Flávio Dino informou que os diretores estão solicitando a realização de concursos PF e PRF anualmente para aumentar o efetivo. Entenda!
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>

         <Box px={{ xs: 0, md: 8 }} className={classes.boxWrapper} style={{ width: 420 }}>
            <Card className={classes.card}>
               <CardActionArea href="https://ead.rbcarreiraspoliciais.com.br/novidades-sobre-os-concursos-da-prf-e-pf">
                  <CardMedia
                     component="img"
                     alt="RB Carreias Policiais"
                     height="254"
                     image={Post3}
                     title="RB Carreias Policiais"
                  />
                  <CardContent className={classes.cardContent}>
                     <Typography gutterBottom variant="body1" component="body1" className={classes.white}>
                        Saiba toda a verdade sobre os próximos concursos da PRF e PF
                     </Typography>
                     <Typography variant="body2" component="p" className={classes.excerpt}>
                        Entenda tudo que acontece nos concursos...
                     </Typography>
                  </CardContent>
               </CardActionArea>
            </Card>
         </Box>

      </Slider>
   );
}