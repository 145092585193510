import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography
} from '@material-ui/core';
import { useTimer } from 'react-timer-hook';

import Bg from '../images/contador-fire.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      background: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: '100%',
      height: 'auto',
      padding: '1rem 0',
   },
   white: {
      color: theme.palette.common.white,
   }
}));

function MyTimer({ expiryTimestamp }) {
   const {
      // totalSeconds,
      seconds,
      minutes,
      hours,
      days,
   } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
   
   const classes = useStyles();

   return (
      <Box className={classes.root}>
         <Container>
            <Grid container justifyContent='center' alignItems='center' spacing={0}>
               <Grid item xs={12} md={5}>
                  <Box className={classes.white} textAlign={{ xs: 'center', md: 'left' }}>
                     <Typography component='span' variant='h4'>
                        SOMENTRE 100 VAGAS DISPONÍVEIS!
                     </Typography>
                  </Box>
               </Grid>
               <Grid item xs={2} md={1}>
                  <Box textAlign='center' className={classes.white}>
                     <Typography component='span' variant='h3'>{days} </Typography>
                     <Typography component='span' variant='body2'>DIAS</Typography>
                  </Box>
               </Grid>
               <Grid item xs={2} md={1}>
                  <Box textAlign='center' className={classes.white}>
                     <Typography component='span' variant='h3'>{hours} </Typography>
                     <Typography component='span' variant='body2'>HOR</Typography>
                  </Box>
               </Grid>
               <Grid item xs={2} md={1}>
                  <Box textAlign='center' className={classes.white}>
                     <Typography component='span' variant='h3'>{minutes} </Typography>
                     <Typography component='span' variant='body2'>MIN</Typography>
                  </Box>
               </Grid>
               <Grid item xs={2} md={1}>
                  <Box textAlign='center' className={classes.white}>
                     <Typography component='span' variant='h3'>{seconds} </Typography>
                     <Typography component='span' variant='body2'>SEG</Typography>
                  </Box>
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
}

export default function Countdown() {
   const time = new Date();
   time.setSeconds(2163010);

   return (

      <MyTimer expiryTimestamp={time} />

   );
}