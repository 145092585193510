import React, { useRef } from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
   List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import './style.css';
// import Form from './Form';

// Components
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import { Icon } from '@iconify/react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Professores from '../../components/Professores';
import Depoimentos from '../../components/Depoimentos';


// Images
import Bg from '../../images/pertencerei-bg-2.webp';
import Bg2 from '../../images/criando-prfs-bg-2.webp';
import Bg3 from '../../images/dease-bg-2.webp';
import Brasao from '../../images/brasao-prf.webp';
import CamisaBg from '../../images/camisa-bg.webp';
import Mockup2 from '../../images/mockup-ead-2.png';
// import Camisa from '../../images/camisa.webp';

const useStyles = makeStyles((theme) => ({
   // Globals
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '650px',
      // maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   container: {
      paddingTop: '10rem', // Sem Countdown
      // paddingTop: '6rem', // Countdown
      // paddingBottom: '3rem', // Countdown

      position: 'relative',
      zIndex: '1',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
         paddingTop: '2rem', // Sem Countdown
         // paddingTop: '0', // Countdown
         // paddingBottom: '1rem', // Countdown
      }
   },
   header: {
      width: '100%',
      height: '100vh',
      maxHeight: '650px',
      // [theme.breakpoints.down('sm')]: {
      //    paddingTop: '8rem',
      // }
   },
   imageHeader: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
         width: '200px',
      }
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   relative: {
      position: 'relative',
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginTop: '-0.35rem',
   },

   // Sobre o curso
   iconFire: {
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      display: 'inline',
      lineHeight: '2rem',
      margin: '0 5px',
      [theme.breakpoints.down('sm')]: {
      }
   },
   ListIconFire: {
      padding: 0,
   },

   // Vantagens do curso
   listItem: {
      height: '90px',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      }
   },

   // Publico alvo
   bg2: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },

   // Seja bem vindo
   bg3: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg3}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },

   CamisaBg: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${CamisaBg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },

   // FAQ
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },
   hubspot: {
      maxHeight: '330px',
      overflow: 'hidden',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '330px',
      },
   },
   box: {
      padding: '2rem',
      overflow: 'hidden',
      borderRadius: '8px',
      backgroundColor: '#13151E !important',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      // color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         // padding: '0',
      }
      // padding: '3rem',
      // backgroundColor: theme.palette.common.white,
      // boxShadow: '0 4px 30px rgba(0, 0, 0, 0.05)',
      // borderRadius: '1rem'
   },
   video: {
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         // padding: '0',
      }
   },
   iconFireBig: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '2rem',
      display: 'inline',
      padding: '0.2rem 0.75rem',
      margin: '0 0 0.75rem',
      [theme.breakpoints.down('sm')]: {
      }
   },
}));

export default function Pertencerei() {
   const classes = useStyles();

   const Inscricao = useRef(null);
   const LinkRef = () =>
      window.scrollTo({
         top: Inscricao.current.offsetTop,
         behavior: "smooth",
      });

   return (
      <Grid className={classes.root}>

         <TopBar />

         <Grid className={classes.section}>
            <Container>
               <Grid
                  container
                  className={classes.container}
                  justifyContent='space-around'
                  alignItems='center'
                  alignContent='center'
               >
                  <Grid item md='5' className={classes.relative}>
                     <Box mt={{ xs: 6, md: 0 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                        {/* <Box mb={4}> */}
                        {/* <Typography component='sup' variant='body2' className={classes.sup}> */}
                        {/* O maior Projeto de estudos para PRF do Brasil. */}
                        {/* </Typography> */}
                        {/* </Box> */}
                        <Box mt={{ xs: 16, md: -12 }} mb={4}>
                           <Typography component='h1' variant='h1' className={classes.white}>
                              Projeto Pertencerei<div className={classes.primary}>Com Ronaldo Bandeira</div>
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              Entre agora no maior Protocolo e Mentoria para aprovações na PRF!
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Button variant='contained' onClick={LinkRef}
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Inscreva-se
                           </Button>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item md='6'>
                     <Box mt={{ xs: 0, md: 0 }} mb={{ xs: -10, md: 0 }} pr={{ xs: 8, md: 0 }} textAlign='center' display={{ xs: 'none', md: 'block' }}>
                        <img src={Brasao} alt='RB Carreiras Policiais - Projeto Pertencerei 2025' width='auto' />
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Grid>

         {/* Sobre o curso */}
         <Box pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={12}>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        {/* eslint-disable-next-line */}
                        <iframe src="https://drive.google.com/file/d/1iBtEHYUBkKyt2wASlMokwPhIOPwXJ3rp/preview" width="720" height="400" allow="autoplay" frameborder="0"></iframe>
                     </Box>
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.white}>
                     <Box mb={{ xs: 4, md: 4 }}>
                        <Box mb={{ xs: 8, md: 8 }} textAlign='left'>
                           <Typography component='p' variant='body1'>
                              O maior Projeto de estudos para PRF do Brasil foi desenvolvido pelo professor Ronaldo Bandeira com cocriação de alunos da comunidade RB que apontaram as maiores dificuldades durante seus estudos.
                           </Typography>
                        </Box>
                        <Box mb={{ xs: 8, md: 8 }} textAlign='left'>
                           <Typography component='p' variant='body1'>
                              O PROJETO PERTENCEREI possui ferramentas inovadoras no mercado com a finalidade de alavancar a eficiência na rotina dos seus estudos.
                           </Typography>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </Box>

         {/* Vantagens dos curso */}
         <Box className={classes.bg2} pt={{ xs: 4, md: 32 }} pb={{ xs: 4, md: 12 }}>
            <Container maxWidth="xl">
               <Grid container justifyContent='center' spacing={8}>
                  <Grid container justifyContent='center' alignItems='flex-start'>
                     <Grid item md={5} className={classes.white}>
                        <Box mb={{ xs: 8 }} textAlign='center'>
                           <Typography component='h2' variant='h2'>
                              <span className={classes.primary}>MAIOR MENTORIA DO BRASIL </span>
                              PARA A PRF COM TODO CONTEÚDO NECESSÁRIO PARA O CONCURSO, CONTENDO:
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
                  <Grid item md={12}>
                     <Grid container justifyContent='space-evenly' alignItems='flex-start' spacing={8}>
                        <Grid item md={4} className={classes.white}>
                           {/* <Box mb={{ xs: 4, md: 4 }} textAlign='center'> */}
                           {/* <img src={Brasao} width='70%' alt='Projeto Pertencerei 2025' /> */}
                           {/* </Box> */}
                           <Box display={{ xs: 'block', md: 'block' }} ml={{ xs: 0, md: 0 }} pt={{ xs: 0, md: 8 }}>
                              <img src={Mockup2} width='100%' alt='' />
                           </Box>
                        </Grid>
                        <Grid item md={3} className={classes.white}>
                           <Box mb={{ xs: 4, md: 4 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          1 ano de acesso ao curso do Pertencerei na plataforma
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Ninho das Águias (Curso de Legislação de Trânsito)
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          DESAFIO PERTENCEREI PRF com metas e missões diárias
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Vídeo-aulas de teoria e exercícios (Fixação e revisão do conteúdo)
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Simulados exclusivos com questões fundamentadas pelos professores
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                        <Grid item md={3} className={classes.white}>
                           <Box mb={{ xs: 0, md: 0 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Quiz com questões comentadas e gabaritadas para revisão de conteúdo
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          PDFs, Mapas mentais e Flashcards
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          RBCoins para troca posterior na RBSTORE
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Professores especialistas e com experiência no mercado
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Oficinas de Redação, Leg. de trânsito e exatas (matemática e física) - aulas, ao vivo, de reforço para sanar duvidas de conteúdo
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Público Alvo */}
         {/* <Box className={classes.bg2} pt={{ xs: 10, md: 30 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}> */}
         {/* <Container> */}
         {/* <Grid container justifyContent='center' alignItems='flex-start'> */}
         {/* <Grid item xs={12} md={3}> */}
         {/* <Box textAlign='left' mb={{ xs: 4, md: 0 }}> */}
         {/* <Typography component='h3' variant='h2' className={classes.white}> */}
         {/* <span className={classes.primary}>Público Alvo</span> */}
         {/* </Typography> */}
         {/* </Box> */}
         {/* </Grid> */}
         {/* <Grid item md={6} className={classes.white}> */}
         {/* <Box mb={{ xs: 4, md: 4 }}> */}
         {/* <Box mb={{ xs: 8, md: 8 }} textAlign='left'> */}
         {/* <Typography component='p' variant='body1'> */}
         {/* Homens e mulheres que estão cansados em gastar seu dinheiro com cursos e não */}
         {/* conseguem conquistar <span className={classes.primary}>SUA TÃO SONHADA APROVAÇÃO.</span> */}
         {/* </Typography> */}
         {/* </Box> */}
         {/* <Box mb={{ xs: 8, md: 8 }} textAlign='left'> */}
         {/* <Typography component='p' variant='body1'> */}
         {/* O compromisso com seu sonho é real, portanto o PROJETO PERTENCEREI nasceu para segurar */}
         {/* sua mão durante essa jornada. O RB Carreiras Policiais já aprovou mais de 3 mil */}
         {/* alunos nos concursos policiais, se tornando referência nacional. */}
         {/* </Typography> */}
         {/* </Box> */}
         {/* </Box> */}
         {/* </Grid> */}
         {/* </Grid> */}
         {/* </Container> */}
         {/* </Box> */}

         {/* Nosso corpo docente... */}
         <Box pt={{ xs: 12, md: 20 }} px={8} mb={8}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid item md={12}>
                  <Box pb={{ xs: 24, md: 24 }} px={{ xs: 4, md: 0 }}>
                     <Grid container justifyContent='center' alignItems='flex-start'>
                        <Grid item xs={12} md={7}>
                           <Box textAlign='center' mb={{ xs: 4, md: 10 }}>
                              <Typography component='h3' variant='h1' className={classes.white}>
                                 O <span className={classes.primary}> mais qualificado acervo</span> de <br /> conteúdo para sua aprovação
                              </Typography>
                           </Box>
                           <Box textAlign='center'>
                              <Typography component='h4' variant='h4' className={classes.white}>
                                 Centenas de aulas gravadas e atualizadas com os mais renomados professores do país.
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
            </Grid>
            <Professores />
         </Box>

         {/* Depoimentos */}
         <Box pt={{ xs: 16, md: 0 }} pb={{ xs: 16, md: 24 }} px={{ xs: 4, md: 0 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start' className={classes.white}>
                  <Grid item md={12}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Icon icon="uil:fire" className={classes.iconFireBig} />
                        <Typography component='h2' variant='h1'>
                           Estudar <span className={classes.primary}>muito</span> é diferente de
                           estudar de <span className={classes.primary}>forma inteligente.</span>
                        </Typography>
                     </Box>
                     <Grid container justifyContent='center'>
                        <Grid item md={7}>
                           <Box mb={{ xs: 8 }} textAlign='center'>
                              <Typography component='h5' variant='h5'>
                                 O compromisso com seu sonho é real, portanto o PROJETO PERTENCEREI nasceu para segurar sua mão durante essa jornada. O RB Carreiras Policiais já aprovou mais de 3 mil alunos nos concursos policiais, se tornando referência nacional.
                              </Typography>
                           </Box>
                           <Box textAlign='center' mb={{ xs: 24, md: 24 }}>
                              <Typography component='h4' variant='h4' className={classes.white}>
                                 Veja o que nossos alunos falam sobre nossos cursos:
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
            <Depoimentos />
         </Box>

         {/* Planos - antigo  */}
         <Box ref={Inscricao} className={classes.bg3} pt={{ xs: 18, md: 30 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}>
            <Container>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='5'>
                     <Box mb={{ xs: 12 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           Faça sua inscrição e <span className={classes.primary}>comece agora </span> a garantir sua aprovação.
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='8'>
                     <Grid container justifyContent='center' spacing={10}>

                        <Grid item md={9}>
                           <Box className={classes.box} p={12}>
                              <Box mb={4} textAlign='center'>
                                 <Typography component='h4' variant='h2'>
                                    Pague com cartão ou Pix
                                 </Typography>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    Pague com toda segurança utilizando a Plataforma da Eduzz.
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    À vista por <Typography component='span' variant='h3'>R$ 597,00</Typography>
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                    <Grid item>
                                       <Typography component='span' variant='h2'>
                                          ou 12x de
                                          <span className={classes.primary}> R$ 59,70</span>
                                       </Typography>
                                    </Grid>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Por tempo limitado
                                 </Typography>
                              </Box>
                              <Box textAlign='center'>
                                 <Button variant='contained' href='https://sun.eduzz.com/2139721'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Pagar agora
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>

                     </Grid>
                  </Grid>
                  <Grid item md='5'>
                     <Box mt={{ xs: 16 }} mb={{ xs: 8 }} px={{ xs: 0 }} className={classes.white} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           <span className={classes.primary}>Dê o primeiro passo rumo à sua aprovação, </span>
                           conquiste sua independência e realize seu sonhe de ser PRF!
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Form de captação de Lead */}
         {/* <Box className={classes.bg3} pt={{ xs: 18, md: 20 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}> */}
         {/* <Container className={classes.container}> */}
         {/* <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'> */}
         {/* <Grid item md='5'> */}
         {/* <Box mb={{ xs: 12 }} ml={{ md: 0 }} textAlign='center'> */}
         {/* <Typography component='h3' variant='h2'> */}
         {/* Deixe seu cadastro que <span className={classes.primary}>em breve </span> avisaremos quando uma nova turma iniciar. */}
         {/* </Typography> */}
         {/* </Box> */}
         {/* </Grid> */}
         {/* <Grid item md='8'> */}
         {/* <Grid container justifyContent='center' spacing={10}> */}
         {/* <Grid item md={12}> */}
         {/* <Form /> */}
         {/* </Grid> */}
         {/* </Grid> */}
         {/* </Grid> */}
         {/* <Grid item md='5'> */}
         {/* <Box mt={{ xs: 16 }} mb={{ xs: 8 }} px={{ xs: 0 }} className={classes.white} textAlign='center'> */}
         {/* <Typography component='p' variant='h5'> */}
         {/* <span className={classes.primary}>Dê o primeiro passo rumo à sua aprovação, </span> */}
         {/* conquiste sua independência e realize seu sonhe de ser PRF! */}
         {/* </Typography> */}
         {/* </Box> */}
         {/* </Grid> */}
         {/* </Grid> */}
         {/* </Container> */}
         {/* </Box> */}

         {/* Inscrição e Perguntas frequentes */}
         <Box pt={{ xs: 18, md: 20 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='8'>
                     <Box mb={{ xs: 12 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           Perguntas frequentes
                        </Typography>
                     </Box>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel1a-content" id="panel1a-header">
                           <Typography className={classes.white}>
                              Nunca estudei para PRF. O PROJETO PERTENCEREI serve para mim?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Sim, principalmente o DESAFIO PERTENCEREI PRF, um cronograma de estudos com metas diárias desenvolvidas para alavancar seus estudos.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.white}>
                              Já estudo para PRF, minha dificuldade é descobrir como a banca pode cobrar os conteúdos?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Aulas gravadas em estúdio com questões elaboradas pelos professores especialistas no mercado, respondendo com embasamento teórico.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.white}>
                              Posso estudar por PDF's?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Sim, nossos PDF's são autossuficientes que possibilitam seus estudos durante uma viagem ou em algum local sem acesso a videoaulas.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.white}>
                              Quero revisar os conteúdo, tenho que assistir as aulas novamente?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Não é necessário. Os mapas mentais com principais pontos te auxiliam nas revisões de um forma dinâmica.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     {/* <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.white}>
                              Como funciona a mentoria de Legislação de Trânsito?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              O ninho das Águias com Ronaldo Bandeira, acontece todos os domingos pela manhã com aulas ao vivo (exceto em dias de plantões).
                           </Typography>
                        </AccordionDetails>
                     </Accordion> */}
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Footer />

      </Grid>
   );
}