import React, { useState, useEffect } from 'react';
import { makeStyles, Container, Grid, Box, Typography } from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
// const axios = require('axios');
// eslint-disable-next-line
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  dark: {
    color: theme.palette.common.black,
    fontWeight: '400',
  },
  dark2: {
    color: '#636360',
  },
  appBarColor: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    height: '95px',
  },
  box: {
    background: 'rgba(236, 239, 247, 0.6)',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  titleBlog: {
    lineHeight: '32px',
  },
  linkBlog: {
    textDecoration: 'none',
    '&:hover': {
      opacity: '0.3',
    }
  },
  icon: {
    fontSize: '2.2rem',
    color: theme.palette.info.main,
    marginRight: '10px',
  },
  image: {
     maxHeight: '250px',
     overflow: 'hidden',
  },
  marginBottom: {
    margin: '0',
  },
  button: {
    backgroundColor: 'transparent',
    padding: '0',
    textAlign: 'center',
    width: '50%',
    margin: '0 auto',
    color: '#F39200',
    display: 'block',
    boxShadow: 'none',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    textTransform: 'inherit',
    opacity:'0.9',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#F39200',
      boxShadow: 'none',
      opacity: '1',
    }
  },
  pagination: {
    cursor: 'pointer',
    margin: '0 0.25rem',
    padding: '0.25rem 0.5rem',
  },
}));

/**
 * Display Articles and Pagination
 **/
function Articles( props ) {

  const classes = useStyles();

  let pagination = [];
  // Show a button to set the current page for each page.
  for( var p = 1; p <= props.totalPages; p++ ) {
    let page = p;
    pagination.push( <Grid item><button className={classes.pagination} onClick={ () => { props.setCurrentPage( page ) } }>{ p }</button></Grid> );
  }
  
  // For all the articles that are passed, add article
  const articles = props.articles.map( ( item, index ) => {
    return <Grid item xs={12} md={4} key={ 'article-' + index }>
        <Box p={5}>
          {/* <a className={classes.linkBlog} href={`${'blog2'}/${item.slug}`}> */}
          <a className={classes.linkBlog} href={`${'blog'}/${item.slug}`}>
            <Grid container className={classes.box}>
              <Grid container alignItems='flex-end' className={classes.image}>
                <img src={item.jetpack_featured_media_url} alt='bgBlog' width='100%' />
              </Grid>
              <Grid item>
                <Box p={5}>
                  <Box display='flex' justifyContent='flex-start'>
                    <Typography
                      variant='h5'
                      className={`${classes.dark} ${classes.marginBottom} ${classes.titleBlog}`}
                      dangerouslySetInnerHTML={{
                        __html: item.title.rendered
                      }}
                    />
                  </Box>
                  <Box py={3}>
                    <Typography 
                      variant='body2'
                      className={classes.dark2}
                      dangerouslySetInnerHTML={{
                        __html: item.excerpt.rendered
                      }}
                    />
                  </Box>
                  {/* <Button className={classes.button} variant="contained" color="secondary">
                      Leia mais
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </a>
        </Box>
      </Grid>
    // );
  });

  return (
    <div>
      <Grid container className="articles">
        { articles }
      </Grid>
      <Grid container justifyContent='center'>
      { pagination }
      </Grid>
    </div>
  )
}

/**
 * Returns articles for the current page.
 * @param {integer} page 
 * @param {array} articles 
 * @param {object} articlesPages 
 */
function getArticlesForPage( page = 1, articles, articlesPages ) {
  // If we don't have them, return empty array
  if ( ! articlesPages[ page ] ) {
    return [];
  }
  // If there are no articles at all, return empty array
  if ( articles.length === 0 ) {
    return [];
  }
 
  let the_articles = [];

  // For each article ID inside of the provided page, 
  // push the whole article object to the array
  for( var i = 0; i < articlesPages[page].length; i++ ) {
    var articleID = parseInt( articlesPages[page][i] );
    for( var a = 0; a < articles.length; a++ ) {
      if ( parseInt( articles[ a ].id ) === articleID ) {
        the_articles.push( articles[ a ] );
        break;
      }
    }
  } 
  // Return array of article objects.
  return the_articles;
}


export default function Blog() {

  const classes = useStyles();

  // Signal if the app is already loading or not
  const [loading, setLoading] = useState(false);
  // All articles that we have loaded
  const [articles, setArticles] = useState([]);
  // Pages with articles IDs
  const [articlesPages, setArticlesPages] = useState({});
  // Current Page
  const [currentPage, setCurrentPage] = useState(1);
  // Total Pages
  const [totalPages, setTotalPages] = useState(0);
  // REST URL
  const url = 'https://ead.rbcarreiraspoliciais.com.br/wp-json/wp/v2/posts';


  // Using useEffect to load new articles on change.
  useEffect(() => {
    // The current page
    const page = currentPage;
    
    // If we are loading, don't try to load again.
    if ( loading ) {
      return;
    }
    
    // If we have articles for that page already, don't load.
    if ( Object.keys( articlesPages ).indexOf( page.toString() ) >= 0 ) {
      return;
    }
    
    // Build up the URL with the page
    const _url   = url + '?page=' + page;
    
    // Set the loading to true.
    setLoading( true );
    
    // Get the posts.
    axios({
        method: 'GET',
        url: _url
    }).then(function (response) {
      if ( response.status === 200 && response.data ) {
        // If we don't have the total pages already, get them from the headers sent by WP.
        if ( totalPages === 0 && Object.keys( response.headers ).indexOf('x-wp-totalpages') >= 0 ) {
          setTotalPages( parseInt( response.headers['x-wp-totalpages'] ) );
        }
        
        const data = response.data;
        // Merge previously loaded articles with new ones.
        let _articles = [ ...articles, ...data ];

        let _articlesPages = articlesPages;
      
        _articlesPages[ page ] = [];
        // Add the loaded page to state with article IDs.
        for( var a=0; a < data.length; a++ ) {
          _articlesPages[ page ].push( data[ a ].id );
        }
      
        setArticlesPages( _articlesPages );
        setArticles( _articles );
      }
    
      setLoading(false);
    })
    .catch(function (error) {
       console.error( error );
    });

  }, [ currentPage, loading, articlesPages, articles, totalPages ]);

  return (

    <div>

      <div className={classes.appBarColor}>
        <TopBar />
      </div>

      <Container>
        <Box my={12}>
          <Box pb={12} pl={4} display='flex' direction='row' justifyContent='center' alignItems='center'>
            <Typography variant='h4' component='h3' className={`${classes.dark} ${classes.marginBottom}`}>
              Blog
            </Typography>
          </Box>
          <Articles 
            currentPage={ currentPage } 
            setCurrentPage={ setCurrentPage }
            articles={ getArticlesForPage( currentPage, articles, articlesPages ) }
            totalPages={ totalPages }
          />
          { loading && <p>Loading</p> }
        </Box>
      </Container>

      <Footer />

    </div>
  
  );
}