import React, { useRef, useState } from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button
} from '@material-ui/core';
import TopBarAntigo from '../components/TopBarAntigo';
import Footer from '../components/Footer';
import { Icon } from '@iconify/react';
// import HubspotForm from 'react-hubspot-form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import PIX from 'react-qrcode-pix';

// eslint-disable-next-line
import copyButton from 'copy-button';


// Images
import Bg from '../images/pmsc-header.webp';
import Bg2 from '../images/pmsc-bg-1.webp';
import Bg3 from '../images/pmsc-bg-2.webp';
import Drica from '../images/professor-drica-v2.webp';
import Eleandra from '../images/professor-eleandra-v2.webp';
import Cobra from '../images/professor-cobra-2-v2.webp';
import Angelo from '../images/professor-angelo-v2.webp';
import Von from '../images/professor-von-v2.webp';
import David from '../images/professor-david-v2.webp';
import Bandeira from '../images/professor-bandeira-v2.webp';
import Ranni from '../images/professor-ranni-v2.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100vh',
      maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   header: {
      // paddingTop: '8rem',
      position: 'relative',
      zIndex: '1',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
         paddingTop: '8rem',
      }
   },
   primary: {
      color: '#b8251e',
   },
   white: {
      color: theme.palette.common.white,
   },
   container: {
      position: 'relative',
   },
   relative: {
      position: 'relative',
   },
   title: {
      textAlign: `center`,
   },
   bold: {
      color: '#428242',
   },
   listItem: {
      display: 'inline-block',
   },
   listItemWhite: {
      display: 'flex',
   },
   button: {
      cursor: 'pointer',
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: '#428242',
      color: theme.palette.primary.contrastText,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(66, 130, 66, 0.6) !important',
         backgroundColor: '#428242',
      },
   },
   buttonOutline: {
      color: '#428242',
      boxShadow: 'none',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      textTransform: 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      borderColor: '#428242',
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(66, 130, 66, 0.6) !important',
         backgroundColor: '#428242',
         color: theme.palette.primary.contrastText,
      },
   },
   copyButton: {
      '& > button': {
         cursor: 'pointer',
         boxShadow: 'none',
         border: 'none',
         borderRadius: '8px',
         padding: '0.75rem 2.5rem',
         fontSize: '1rem',
         fontWeight: '700',
         backgroundColor: '#428242',
         color: theme.palette.primary.contrastText,
         textTransform: 'uppercase',
         '&:hover': {
            boxShadow: '0px 6px 30px rgba(66, 130, 66, 0.6) !important',
            backgroundColor: '#428242',
         },
      }
   },
   box: {
      // padding: '2rem',
      overflow: 'hidden',
      borderRadius: '8px',
      backgroundColor: '#13151E',
      // border: '1px solid #fff',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         // padding: '0',
      }
   },
   icon: {
      float: 'left',
      color: '#428242',
      fontSize: '1.5rem',
      marginTop: '0.1rem',
      marginRight: '1rem',
      // marginBottom: '1rem',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
         // marginBottom: '2rem',
      }
   },
   bg2: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      },
   },
   bg3: {
      height: '100%',
      backgroundImage: `url('${Bg3}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      },
   },
   iconFire: {
      textAlign: 'center',
      color: '#b8251e',
      fontSize: '1.5rem',
      display: 'inline',
      padding: '0  0.75rem',
      [theme.breakpoints.down('sm')]: {
      }
   },
   iconArrowDown: {
      textAlign: 'center',
      color: '#428242',
      fontSize: '1.5rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },
   hubspot: {
      maxHeight: '480px',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '490px',
      },
   },
   degrade: {
      backgroundImage: 'linear-gradient(90deg,#996dff,#ff927b 24%,#ffd47f 52%,#ffbf40 79%,#ff5c39)',
      '-webkit-text-fill-color': 'transparent',
      backgroundClip: 'text',
   },
}));

// Para efeutar pagamento via pix
// const now = new Date().getTime().toString();

export default function Pmsc() {
   const classes = useStyles();

   const PlanosRef = useRef(null);
   const LinkPlanos = () =>
      window.scrollTo({
         top: PlanosRef.current.offsetTop,
         behavior: "smooth",
      });

   // Para efeutar pagamento via pix
   // eslint-disable-next-line
   const [viewPIX, setViewPIX] = useState("");

   return (
      <Grid className={classes.root}>

         <TopBarAntigo />

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid
                  container
                  className={classes.header}
               >
                  <Grid item md='4' className={classes.relative}>
                     <Box mt={{ xs: 6, md: 0 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.white}>
                              <div className={classes.primary}>PMSC</div> Operação aprovação
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              O edital está lançado e chegou a hora de você se preparar com o curso campeão em aprovações em carreiras policiais.
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Button variant='contained' onClick={LinkPlanos}
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Garanta agora sua vaga
                           </Button>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Grid>

         {/* No último concurso da PMSC... */}
         <Box className={classes.bg2} pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           No último concurso da PMSC <span className={classes.primary}>aprovamos 70% dos alunos</span> matriculados, agora é a hora de você garantir a sua vaga
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           Serão 400 vagas masculinas e 100 femininas com salário inicial de <span className={classes.primary}>R$ 6.000,00</span> além de auxílios e benefícios.
                        </Typography>
                     </Box>
                     <Box textAlign='center'>
                        <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} />
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Porque escolher o Operação Aprovação? */}
         <Box pt={{ xs: 18, md: 30 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 30 }}>
            <Container className={classes.container}>

               <Grid className={classes.white} container justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='5'>
                     <Box mb={{ xs: 8 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h1'>
                           <span className={classes.primary}>Porque</span> escolher o Operação Aprovação?
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Além de possuirmos a melhor metodologia de ensino, sermos especializados em
                           CEBRASPE e possuirmos o maior índice de aprovação do país, você também terá acesso:
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='6'>
                     {/* <Box mb={{ xs: 4, md: 4 }}> */}
                     <Grid container alignContent='center' justifyContent='center' direction='column'>
                        <Grid item xs={12} md={8}>
                           <Box mt={{ xs: 8 }} mb={{ xs: 8, md: 2 }} px={{ xs: 0, md: 6 }} className={classes.white}>
                              <Typography component='p' variant='h5'>
                                 <span className={classes.primary}>Estrutura completa de ensino</span>
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Desafio Operação Aprovação PMSC com um programa de
                                 metas diárias para alavancar e otimizar seus estudos;
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Curso completo gravado com os
                                 melhores professores do Brasil;
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Acesso online ao vivo e gravado
                                 de todas as nossas aulas
                                 do Presencial Reta Final;
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Materiais complementares;
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Cronograma de estudos;
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Grupo de estudos.
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                     {/* </Box> */}
                  </Grid>
               </Grid>

               {/* Acesso online ao Presencial... */}
               <Grid item md={12}>
                  <Box mt={{ xs: 12, md: 16 }} pb={{ xs: 0, md: 0 }} px={{ xs: 4, md: 0 }}>
                     <Grid container justifyContent='space-between' alignItems='flex-start'>

                        <Grid item xs={12} md={12}>
                           <Box textAlign='center' mb={{ xs: 4, md: 10 }}>
                              <Typography component='h3' variant='h1' className={classes.white}>
                                 Acesso online ao Presencial Reta Final <br /><span className={classes.primary}> com os melhores professores:</span>
                              </Typography>
                           </Box>
                        </Grid>

                        <Grid container justifyContent='flex-start' spacing={8}>
                           <Grid item md={3}>
                              <img src={Bandeira} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Drica} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Eleandra} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Cobra} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Angelo} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Von} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={David} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Ranni} width='100%' alt='' />
                           </Grid>
                        </Grid>

                        <Grid item xs={12} md={12}>
                           <Box textAlign='center' mt={12}>
                              <Typography component='h3' variant='h2' className={classes.white}>
                                 Montamos uma equipe dos sonhos com uma só missão:
                              </Typography>
                              <Typography component='h3' variant='h1' className={classes.primary}>
                                 A sua aprovação! <Icon icon="simple-line-icons:check" className={classes.iconFire} />
                              </Typography>
                           </Box>
                        </Grid>

                     </Grid>
                  </Box>
               </Grid>

            </Container>
         </Box>

         {/* Pagamento */}
         {/* <Box ref={PlanosRef} className={classes.bg3} pt={{ xs: 18, md: 40 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 30 }}>
            <Container className={classes.container}>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='5'>
                     <Box mb={{ xs: 12 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           Faça sua inscrição e <span className={classes.primary}>comece agora </span> a garantir sua aprovação.
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='5'>
                     <Grid container justifyContent='space-between' spacing={10}>
                        <Grid item md={12}>
                           <Box className={classes.box} p={12}>
                              <Box className={classes.hubspot}>
                                 <HubspotForm
                                    portalId='39589522'
                                    formId='78bc1e74-e030-48c2-84ea-97f566c6264a'
                                    onSubmit={() => console.log('Enviado!')}
                                    onReady={(form) => console.log('Formulário pronto!')}
                                    loading={<div>Carregando...</div>}
                                 />
                                 <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                              </Box>
                           </Box>
                           <Box className={classes.box} p={12}>
                              <Box mb={4} textAlign='center'>
                                 <Typography component='h4' variant='h2'>
                                    Plano digital
                                 </Typography>
                              </Box>
                              <Box mb={12} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Acesso à todos os conteúdos online direto da sua casa e
                                    com transmissão ao vivo do Presencial Reta Final.
                                 </Typography>
                              </Box>
                              <Box mb={3} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    À vista por <Typography component='span' variant='h3'>R$249</Typography>
                                 </Typography>
                              </Box>
                              <Box mb={3} textAlign='center'>
                                 <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                                    <Grid item md={12}>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                       <Typography component='span' variant='h1'>
                                          ou 12x de
                                          <span className={classes.primary}> R$ 24,90</span>
                                       </Typography>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={12} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Por tempo limitado
                                 </Typography>
                              </Box>
                              <Box textAlign='center'>
                                 <Button variant='contained' href='http://mpago.la/2sSH46n'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Garanta agora sua vaga
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item md='5'>
                     <Box mt={{ xs: 16 }} mb={{ xs: 8 }} px={{ xs: 0 }} className={classes.white} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           Dê o primeiro passo rumo à sua aprovação,
                           conquiste sua independência e more em um dos estados
                           mais desenvolvidos e seguros do Brasil.
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box> */}

         {/* Planos - antigo */}
         <Box ref={PlanosRef} className={classes.bg3} pt={{ xs: 18, md: 20 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}>
            <Container className={classes.container}>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='5'>
                     <Box mb={{ xs: 12 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           Faça sua inscrição e <span className={classes.primary}>comece agora </span> a garantir sua aprovação.
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='8'>
                     <Grid container justifyContent='space-between' spacing={10}>

                        <Grid item md={6}>
                           <Box className={classes.box} p={12}>
                              <Box mb={4} textAlign='center'>
                                 <Typography component='h4' variant='h2'>
                                    Pague com cartão
                                 </Typography>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Pague com toda segurança utilizando a Plataforma do Mercado Pago.
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    À vista por <Typography component='span' variant='h3'>R$298,80</Typography>
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                    <Grid item>
                                       <Typography component='span' variant='h2'>
                                          ou 12x de
                                          <span className={classes.primary}> R$ 24,90</span>
                                       </Typography>
                                    </Grid>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Por tempo limitado
                                 </Typography>
                              </Box>
                              <Box textAlign='center'>
                                 <Button variant='contained' href='http://mpago.la/2sSH46n'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Pagar agora
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>

                        <Grid item md={6}>
                           <Box className={classes.box} p={12}>
                              <Grid container justifyContent='' spacing={8}>
                                 <Grid item md={12}>
                                    <Box mb={4} textAlign='center'>
                                       <Typography component='h4' variant='h2'>
                                          Pague com pix
                                       </Typography>
                                    </Box>
                                    <Box mb={8} textAlign='center'>
                                       <Typography component='p' variant='body2'>
                                          Receba um desconto extra efetuando sua inscrição com pagamento via Pix.
                                       </Typography>
                                    </Box>
                                    <Box mb={2} textAlign='center'>
                                       <Typography component='p' variant='body1'>
                                          À vista
                                       </Typography>
                                    </Box>
                                    <Box mb={2} textAlign='center'>
                                       <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                                          <Grid item md={12}>
                                             <Icon icon="uil:fire" className={classes.iconFire} />
                                             <Typography component='span' variant='h2'>
                                                <span className={classes.primary}> R$ 249,00</span>
                                             </Typography>
                                             <Icon icon="uil:fire" className={classes.iconFire} />
                                          </Grid>
                                       </Grid>
                                    </Box>
                                    <Box mb={8} textAlign='center'>
                                       <Typography component='p' variant='body2'>
                                          Por tempo limitado
                                       </Typography>
                                    </Box>
                                    <Box mb={0} textAlign='center'>
                                       <copy-button target-text="00020126580014BR.GOV.BCB.PIX0136246bd709-6f03-456c-a6ef-196260a799715204000053039865406249.005802BR5925RONALDO BANDEIRA.COM.BR C6009SAO PAULO61080540900062240520ITrv6p83uGnEJmscyucd6304E112" class={classes.copyButton}>
                                          Copiar código PIX
                                       </copy-button>
                                    </Box>
                                 </Grid>
                                 {/* <Grid item md={6}>
                                    <Box mb={3} textAlign='center'>
                                       <PIX
                                          // pixkey='adm@ronaldobandeira.com.br'
                                          // merchant='Ronaldo Braga Bandeira Júnior'
                                          // city='Balneário Camboriú'
                                          pixkey='246bd709-6f03-456c-a6ef-196260a79971'
                                          merchant='RONALDO BANDEIRA.COM.BR CURSOS PREPARATORIOS LTDA'
                                          city='São Paulo'
                                          amount={249}
                                          onLoad={payload => setViewPIX(payload)}
                                          // onLoad={setViewPIX}
                                          // code={'RQP' + now}
                                          // cep='25.850-000'
                                          // resize={200}
                                       />
                                       <p>{viewPIX}</p>
                                    </Box>
                                    <Box mb={3} textAlign='center'>
                                       <copy-button target-text="00020126580014BR.GOV.BCB.PIX0136246bd709-6f03-456c-a6ef-196260a799715204000053039865406249.005802BR5925RONALDO BANDEIRA.COM.BR C6009SAO PAULO61080540900062240520ITrv6p83uGnEJmscyucd6304E112" class={classes.copyButton}>
                                          Copie o código
                                       </copy-button>
                                    </Box>
                                 </Grid> */}
                              </Grid>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item md='5'>
                     <Box mt={{ xs: 16 }} mb={{ xs: 8 }} px={{ xs: 0 }} className={classes.white} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           <span className={classes.primary}>Dê o primeiro passo rumo à sua aprovação, </span>
                           conquiste sua independência e more em um dos estados mais desenvolvidos e seguros do Brasil.
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* FAQ */}
         <Box pt={{ xs: 18, md: 20 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}>
            <Container className={classes.container}>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='8'>
                     <Box mb={{ xs: 12 }} textAlign='center'>
                        <Typography component='h3' variant='h1'>
                           FAQ
                        </Typography>
                     </Box>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel1a-content" id="panel1a-header">
                           <Typography className={classes.white}>
                              Porque devo estudar com o Desafio Operação Aprovação do RB?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              O Desafio Operação Aprovação PMSC é um programa completo com metas diárias diárias desenvolvidas
                              para potencializar seus estudos. São aulas teóricas e aulas com exercícios onde os professores
                              respondem as questões com embasamento teórico além dos materiais complementares.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.white}>
                              Quem comprar o Plano Digital terá acesso às aulas do Presencial Reta Final?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Sim! Você terá acesso às transmissões ao vivo de todas as aulas do Presencial Reta
                              Final e também poderá assistir novamente sempre que quiser.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel3a-content" id="panel3a-header">
                           <Typography className={classes.white}>
                              Poderei assistir as aulas quando quiser e quantas vezes quiser?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Sim! Você poderá acessar todo o nosso material online sempre que quiser e se reforçar
                              sempre que precisar.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel4a-content" id="panel4a-header">
                           <Typography className={classes.white}>
                              Posso dividir meu acesso com um amigo?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Não! Seu acesso ao curso é de uso pessoal e intransferível e qualquer quebra dos
                              termos de uso será passível de penalidade e até cancelamento do seu acesso.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box position='relative' zIndex='1'>
            <Footer />
         </Box>

      </Grid >
   );
}