import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
   List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';

// Components
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import { Icon } from '@iconify/react';

// Images
import Bg from '../../images/agente-de-transito-bg.webp';
import Bg2 from '../../images/agente-de-transito-bg-2.webp';
import Metodo from '../../images/ronaldo.webp';
import Drica from '../../images/professor-drica.webp';
import Eleandra from '../../images/professor-eleandra.webp';
import Von from '../../images/professor-von.webp';

const useStyles = makeStyles((theme) => ({
   // Globals
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100%',
      // maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   header: {
      width: '100%',
      height: '100vh',
      maxHeight: '650px',
      // [theme.breakpoints.down('sm')]: {
      //    paddingTop: '8rem',
      // }
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   black: {
      color: theme.palette.common.black,
   },
   contrastText: {
      color: theme.palette.common.main,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   buttonDark: {
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.black,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.common.white,
         color: theme.palette.common.black,
      },
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   relative: {
      position: 'relative',
   },

   // Seja bem vindo
   bg2: {
      width: 'auto',
      height: 'auto',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   iconArrowDown: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },

   // O que faz um policial civil?
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginRight: '1rem',
      marginTop: '-0.15rem',
   },

   // FAQ
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },

   // Garanta sua vaga
   boxComprar: {
      padding: '1.5rem 0 1rem 0',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '12px',
   },

   // Beneficios
   listItem: {
      height: '100px',
   },

   // Bloco de contato
   contatoBox: {
      padding: '1.25rem 2rem !important',
      fontWeight: 'bold !important',
      borderRadius: '12px',
      textAlign: 'center',
      border: '1px solid rgba(128,136,146,0.2)',
      color: theme.palette.common.white,
   },

}));

export default function AgenteDeTransito() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid className={classes.header} container justifyContent='flex-start' alignItems='center'>
                  <Grid item md='5'>
                     <Box mt={{ xs: 6, md: 0 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.white}>
                              <div className={classes.primary}>Agente de Trânsito</div>
                              Balneário Camboriú
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              Curso preparatório On-line
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              <span className={classes.secondary}>De R$ 599</span> - <span className={classes.primary}>Por R$ 499</span>
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Grid item md={6}>
                              <Button variant='contained' href='/ninho-das-aguias'
                                 classes={{
                                    root: classes.button,
                                    label: classes.buttonLabel,
                                 }}>Comprar agora
                              </Button>
                           </Grid>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item md='5'></Grid>
               </Grid>
            </Container>
         </Grid>

         {/* SOBRE O CURSO */}
         <Box pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 10 }}>
            <Container>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={7} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>CURSO PREPARATÓRIO ONLINE </span>
                           PARA AGENTE DE TRÂNSITO.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 8 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           O edital para Agente de Trânsito de Balneário Camboriú está autorizado.
                           O momento para estudar é <span className={classes.primary}>AGORA! </span>
                           Baseado no último edital com a Banca UNISOCIESC e com garantia de atualização Pós-edital.
                        </Typography>
                     </Box>
                     <Box textAlign='center'>
                        <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} />
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Qual e a função... */}
         <Box className={classes.bg2} pt={{ xs: 10, md: 30 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container>
               <Grid container justifyContent='space-around' spacing={4}>

                  <Grid item md={5}>
                     <Box py={{ xs: 4, md: 4 }}>
                        <Grid container justifyContent='center'>
                           <Grid item md={12} className={classes.white}>
                              <Box mb={{ xs: 8 }} textAlign='left'>
                                 <Typography component='h2' variant='h2'>
                                    <span className={classes.primary}>Qual e a função </span>
                                    de um Agente de Trânsito?
                                 </Typography>
                              </Box>
                              <Box mb={{ xs: 8, md: 12 }} textAlign='left'>
                                 <Typography component='p' variant='body1'>
                                    Os agentes de trânsito têm como missão oferecer ao cidadão condições de
                                    se deslocar com segurança e conforto, garantindo a qualidade de vida de
                                    toda a população”, disse. A função do agente é efetivar intervenções
                                    para garantir o melhor desempenho do trânsito.
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                     {/* Público alvo */}
                     <Box py={{ xs: 4, md: 4 }}>
                        <Grid container justifyContent='center'>
                           <Grid item md={12} className={classes.white}>
                              <Box mb={{ xs: 8 }} textAlign='left'>
                                 <Typography component='h2' variant='h2'>
                                    <span className={classes.primary}>Qual é a média salarial? </span>
                                    Possui benefícios?
                                 </Typography>
                              </Box>
                              <Box mb={{ xs: 8, md: 12 }} textAlign='left'>
                                 <Typography component='p' variant='body1'>
                                    A média salarial dos agentes de trânsito é R$ 5 mil reais incluindo
                                    vale alimentação, adicional por periculosidade. Além do plano de
                                    saúde para o servidor e todos os seus dependentes.
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                  </Grid>

                  <Grid item md={5}>
                     {/* Qual a jornada */}
                     <Box py={{ xs: 4, md: 4 }}>
                        <Grid container justifyContent='center'>
                           <Grid item md={12} className={classes.white}>
                              <Box mb={{ xs: 8 }} textAlign='left'>
                                 <Typography component='h2' variant='h2'>
                                    <span className={classes.primary}>Qual a jornada </span>
                                    de trabalho?
                                 </Typography>
                              </Box>
                              <Box mb={{ xs: 8, md: 12 }} textAlign='left'>
                                 <Typography component='p' variant='body1'>
                                    A jornada mais comum costuma ser 12×36 e 12×60, ou seja, ele trabalha
                                    12 horas e tem 36 horas de descanso, depois trabalha mais 12 horas e
                                    tem 60 horas de descanso, que costuma ser nos finais de semana.
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                     {/* Garanta sua vaga */}
                     <Box mt={{ xs: 4, md: 4 }} className={classes.boxComprar}>
                        <Grid container justifyContent='center'>
                           <Grid item md={12} className={classes.white}>
                              <Box mb={{ xs: 4 }} textAlign='center'>
                                 <Typography component='h2' variant='h2'>
                                    <span className={classes.black}>Garanta a sua vaga agora mesmo </span>
                                 </Typography>
                              </Box>
                              <Box mb={4} textAlign='center'>
                                 <Button variant='contained' href='/agente-de-transito'
                                    classes={{
                                       root: classes.buttonDark,
                                       label: classes.buttonLabel,
                                    }}>Comprar agora
                                 </Button>
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                  </Grid>

               </Grid>
            </Container>
         </Box>

         {/* Estude com quem é... */}
         <Container>
            <Grid container justifyContent='flex-start' alignItems='center' spacing={8}>
               <Grid item md={6}>
                  <Box ml={{ xs: 0, md: -12 }}>
                     <img src={Metodo} width='100%' alt='' />
                  </Box>
               </Grid>
               <Grid item md={5}>
                  <Box mb={{ xs: 8, md: 8 }} className={classes.white}>
                     <Typography component='h2' variant='h2'>
                        Estude com quem é <span className={classes.primary}>REFERÊNCIA NACIONAL EM APROVAÇÕES.</span>
                     </Typography>
                  </Box>
                  <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                     <Typography component='div' variant='body1'>
                        <span className={classes.white}>Com +3.000 alunos aprovados</span> em menos de 4 anos. E ainda adquirindo até o dia xx de xxxxx de 2023, você ganha de Bônus o <span className={classes.white}>NINHO DAS ÁGUIAS.</span>
                     </Typography>
                  </Box>
                  <Box mb={{ xs: 8, md: 4 }} className={classes.secondary}>
                     <Typography component='div' variant='body1'>
                        Mentoria de Legislação de Trânsito com Professor Ronaldo Bandeira (Embaixador do CTB).
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
         </Container>

         {/* O que você vai aprender */}
         <Box mt={{ xs: 12, md: 16 }} mb={{ xs: 10, md: 30 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item xs={12} md={12}>
                     <Box textAlign='center' mb={{ xs: 4, md: 10 }}>
                        <Typography component='h3' variant='h2' className={classes.white}>
                           O que você <span className={classes.primary}>vai aprender?</span>
                        </Typography>
                        <Typography component='p' variant='body1' className={classes.contrastText}>
                           Mais que o necessário. A maior CARGA HORÁRIA DA REGIÃO
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid container justifyContent='flex-start' spacing={8}>
                     <Grid item md={3}>
                        <img src={Drica} width='100%' alt='' />
                     </Grid>
                     <Grid item md={3}>
                        <img src={Eleandra} width='100%' alt='' />
                     </Grid>
                     <Grid item md={3}>
                        <img src={Von} width='100%' alt='' />
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Público alvo */}
         <Box pt={{ xs: 18, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}>
            <Container className={classes.container}>
               <Grid container className={classes.white} justifyContent='center' alignItems='flex-start'>
                  <Grid item md='3'>
                     <Box mb={{ xs: 8 }} ml={{ md: 0 }} textAlign='left'>
                        <Typography component='h3' variant='h2'>
                           <span className={classes.primary}>Público</span> alvo
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='5'>
                     <Grid container alignContent='center' justifyContent='flex-start'>
                        <Grid item md={12}>
                           <Box mb={{ xs: 8, md: 12 }} textAlign='left'>
                              <Typography component='p' variant='body1'>
                                 Homens e mulheres que decidiram mudar sua vida e realizar seus sonhos
                                 e de sua família por meio de concursos Públicos.
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box p={3} textAlign='left'>
                              <Icon icon="ph:seal-check-thin" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Brasileiro
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box p={3} textAlign='left'>
                              <Icon icon="ph:seal-check-thin" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Maior de 18 anos
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box p={3} textAlign='left'>
                              <Icon icon="ph:seal-check-thin" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Ensino médio completo
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box p={3} textAlign='left'>
                              <Icon icon="ph:seal-check-thin" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Carteira de Motorias A e B
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Benefícios do curso */}
         <Box pt={{ xs: 18, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 20 }}>
            <Container className={classes.container}>
               <Grid className={classes.white} container justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='5'>
                     <Box mb={{ xs: 12 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           <span className={classes.primary}>Benefícios</span> do curso
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md={10}>
                     <Grid container justifyContent='space-around' alignItems='flex-start' spacing={8}>
                        <Grid item md={4} className={classes.white}>
                           <Box mb={{ xs: 4, md: 0 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Maior carga horaria de CTB do brasil com o EMBAIXADOR RONALDO BANDEIRA
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Mais de 200h de curso entre ao vivo e estúdio
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Videoaulas teoria e exercícios
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                        <Grid item md={4} className={classes.white}>
                           <Box mb={{ xs: 4, md: 0 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Simulado pós edital formato FEPESE comentado e gabaritado
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Melhor corpo docente do brasil
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start">
                                    <ListItemText>
                                       <Typography variant='h5' component='span' className={classes.primary}>
                                          Tá esperando o quê para começar?
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                        <Grid item md={4} className={classes.white}>
                           <Box mb={{ xs: 4, md: 0 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Ebooks de todo conteúdo programático + questões comentadas
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="ph:star-light" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Direito as aulas ao vivo (lives do presencial da turma específica)
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <Button variant='contained' href='/pmsc'
                                       classes={{
                                          root: classes.button,
                                          label: classes.buttonLabel,
                                       }}>Comprar agora
                                    </Button>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Contatos */}
         <Box>
            <Container>
               <Box mt={36}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     <Grid item md={6}>
                        <Box pr={{ xs: 0, md: 6 }}>
                           <Grid container justifyContent='center' alignItems='center' className={classes.contatoBox}>
                              <Grid item>
                                 <Icon icon="fluent:mail-16-regular" className={classes.iconContact} />
                              </Grid>
                              <Grid item>
                                 <Typography component='span' variant='h4'>adm@ronaldobandeira.com.br</Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                     <Grid item md={6}>
                        <Box pr={{ xs: 0, md: 6 }}>
                           <Grid container justifyContent='center' alignItems='center' className={classes.contatoBox}>
                              <Grid item>
                                 <Icon icon="ph:phone" className={classes.iconContact} />
                              </Grid>
                              <Grid item>
                                 <Typography component='span' variant='h4'>Central de vendas: (47) 988.131.174</Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>

         <Footer />

      </Grid>
   );
}