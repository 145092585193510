import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
} from '@material-ui/core';

// Components
import TopBarRenascimento from '../../components/TopBarRenascimento';
import Footer from '../../components/Footer';
import { Icon } from '@iconify/react';

// Images
import Bg from '../../images/renascimento-bg.webp';
import Bg2 from '../../images/renascimento-bg-2.webp';
import Foto from '../../images/renascimento-foto.webp';

const useStyles = makeStyles((theme) => ({
   // Globals
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100%',
      // maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   header: {
      width: '100%',
      height: '100vh',
      maxHeight: '650px',
      // [theme.breakpoints.down('sm')]: {
      //    paddingTop: '8rem',
      // }
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   relative: {
      position: 'relative',
   },

   // Seja bem vindo
   bg2: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   iconArrowDown: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },

   // O que faz um policial civil?
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginRight: '1rem',
   },

   // FAQ
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },
   h1: {
      color: theme.palette.common.white,
      fontSize: '4rem',
   },

}));

export default function Renascimento() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBarRenascimento />

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid className={classes.header} container justifyContent='center' alignItems='center'>
                  <Grid item md='5'>
                     <Box mt={{ xs: 6, md: 54 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 0 }} textAlign='center'>
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.h1}>
                              O Renascimento do RB
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              Há cinco anos nós revolucionamos a preparação para concursos em Carreiras Policiais.
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.primary}>
                              Hoje nós vamos mudar a história novamente.
                           </Typography>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Grid>

         {/* De cinco anos pra cá... */}
         <Box mt={{ xs: 0, md: -36 }} pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }} position='relative' zIndex={9}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <img src={Foto} width='100%' alt='RB Carreiras Policiais' />
                     </Box>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           De cinco anos pra cá o mundo mudou e a maneira de se preparar por concursos também.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Plataformas frias, milhares de PDFs sem sentido e uma experiência maçante só 
                           tornarão sua jornada muito mais pesada e farão muitos desistirem pelo caminho.
                        </Typography>
                     </Box>
                     <Box textAlign='center'>
                        <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} />
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* O Novo RB ouviu você */}
         <Box className={classes.bg2} pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={4} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>
                              O Novo RB ouviu você, entendeu suas dores e conhece seus desafios.
                           </span>
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Nós unimos o melhor e mais vasto conteúdo especializado em 
                           Carreiras Policiais à uma nova tecnologia de ensino para 
                           tornar seu processo muito mais agradável e eficiente.
                        </Typography>
                     </Box>
                     <Box textAlign='center'>
                        <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} />
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </Box>

         {/* Você tem um sonho... */}
         <Box pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           Você tem um sonho e sabe onde quer chegar.  
                           <div className={classes.primary}> 
                              Nós temos um mapa e toda a 
                              estrutura para tornar sua jornada muito mais leve. 
                           </div>
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 8 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Conheça a Nova Assinatura do RB Carreiras Policiais e 
                           tenha acesso ao conteúdo e plataforma que mais 
                           aprovou em carreiras policiais no Brasil!
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md={12}>
                     <Box mt={8} textAlign='center'>
                        <Button variant='contained' href='http://mpago.la/2N6H9bs'
                           classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                           }}>Acessar o Novo RB
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>


         <Footer />

      </Grid>
   );
}