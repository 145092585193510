import React from 'react';
import Slider from 'react-slick';
import '../styles/slick.css';
import '../styles/slick-theme.css';
import { makeStyles, Box, Grid } from '@material-ui/core';

// Images
import Depoimento1 from '../images/depoimento1.webp';
import Depoimento2 from '../images/depoimento2.webp';
import Depoimento3 from '../images/depoimento3.webp';
import Depoimento4 from '../images/depoimento4.webp';
import Depoimento5 from '../images/depoimento5.webp';
import Depoimento6 from '../images/depoimento6.webp';
import Depoimento7 from '../images/depoimento7.webp';
import Depoimento8 from '../images/depoimento8.webp';
import Depoimento9 from '../images/depoimento9.webp';
import Depoimento10 from '../images/depoimento10.webp';

const useStyles = makeStyles((theme) => ({
   box: {
      overflow: 'hidden',
      backgroundColor: '#13151E',
      borderRadius: '8px',
      position: 'relative',
      minHeight: '490px',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      // padding: '2rem 1.5rem'
   },
   white: {
      color: theme.palette.common.white,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   img: {
      width: '100%',
      // display: 'inline !important',
      borderRadius: '5px',
   },
   imageBg: {
      margin: '0 5px',
      height: '340px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'transparent !important',
   },
   card: {
      // borderRadius: '4px',
      // backgroundColor: '#13151E',
      // minHeight: '550px',
   },
   cardContent: {
      padding: '2rem',
      minHeight: '280px',
   },
   excerpt: {
      color: '#738292',
      paddingTop: '1rem',
   },
   boxHeader: {
      [theme.breakpoints.down('sm')]: {
         width: '100vw !important',
      },
   },
   cardHeader: {
      borderRadius: '5px',
      backgroundColor: '#13151E',
      padding: theme.spacing(12),
      minHeight: '550px',
      [theme.breakpoints.down('sm')]: {

      },
   },
   boxWrapper: {
      width: 'auto !important',
      // maxWidth: '350px',
      // padding: '1px',
      [theme.breakpoints.down('sm')]: {
         // width: '100% !important',
      },
   },
}));

export default function Curso() {

   const classes = useStyles();
   var settings = {
      className: "slider",
      dots: false,
      arrows: true,
      lazyLoad: false,
      infinite: true,
      speed: 500,
      initialSlide: 0,
      slidesToShow: 5,
      slidesToScroll: 5,
      autoplay: true,
      autoplaySpeed: 3000,
      swipeToSlide: true,
      variableWidth: false,
      responsive: [
         {
            breakpoint: 1441,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 4,
            }
         },
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (
      <Grid container justifyContent='center'>
         <Grid item xs={12} md={11}>
            <Slider {...settings}>

               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento1} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento2} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento3} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento4} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento5} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento6} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento7} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento8} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento9} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>
               <Box px={2} className={classes.boxWrapper}>
                  <img src={Depoimento10} className={classes.img} alt='Depoimento de aluno no RB Carreiras Policiais' />
               </Box>

            </Slider>
         </Grid>
      </Grid>

   );
}