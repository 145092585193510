import React from 'react';
import Slider from 'react-slick';
import '../styles/slick.css';
import '../styles/slick-theme.css';
import Card from '@material-ui/core/Card';
import { makeStyles, Box } from '@material-ui/core';

// Images
import Pcsc from '../images/card-pcsc.webp';
import Pertencerei from '../images/card-pertencerei.webp';
import NinhoDasAguias from '../images/card-ninho-das-aguias.webp';
// import Pf from '../images/card-pf.webp';
import Dease from '../images/card-dease.webp';
import Navegantes from '../images/card-gm-navegantes.webp';

const useStyles = makeStyles((theme) => ({
   box: {
      overflow: 'hidden',
      backgroundColor: '#13151E',
      borderRadius: '8px',
      position: 'relative',
      minHeight: '490px',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      // padding: '2rem 1.5rem'
   },
   white: {
      color: theme.palette.common.white,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   image: {
      width: '100%',
      height: '100%',
      borderRadius: '175px',
   },
   imageBg: {
      margin: '0 5px',
      height: '340px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'transparent !important',
   },
   card: {
      // borderRadius: '4px',
      // backgroundColor: '#13151E',
      // minHeight: '550px',
   },
   cardContent: {
      padding: '2rem',
      minHeight: '280px',
   },
   excerpt: {
      color: '#738292',
      paddingTop: '1rem',
   },
   boxHeader: {
      [theme.breakpoints.down('sm')]: {
         width: '100vw !important',
      },
   },
   cardHeader: {
      borderRadius: '5px',
      backgroundColor: '#13151E',
      padding: theme.spacing(12),
      minHeight: '550px',
      [theme.breakpoints.down('sm')]: {

      },
   },
   boxWrapper: {
      // width: '396px',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
         width: '100% !important',
      },
   },
}));

export default function Curso() {

   const classes = useStyles();
   var settings = {
      className: "slider variable-width",
      dots: false,
      arrows: true,
      lazyLoad: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 5,
      swipeToSlide: true,
      variableWidth: true,
      responsive: [
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 0,
               autoplay: true,
               autoplaySpeed: 3000,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (
      <Slider {...settings}>

         <Box px={4} className={classes.boxWrapper} style={{ width: 390 }}>
            <a href='/pertencerei'><Card className={classes.imageBg} style={{ backgroundImage: `url(${Pertencerei})` }}></Card></a>
         </Box>

         <Box px={4} className={classes.boxWrapper} style={{ width: 240 }}>
            <a href='/pcsc'><Card className={classes.imageBg} style={{ backgroundImage: `url(${Pcsc})` }}></Card></a>
         </Box>

         <Box px={4} className={classes.boxWrapper} style={{ width: 240 }}>
            <a href='/ninho-das-aguias'><Card className={classes.imageBg} style={{ backgroundImage: `url(${NinhoDasAguias})` }}></Card></a>
         </Box>

         {/* <Box px={4} className={classes.boxWrapper} style={{ width: 240 }}> */}
            {/* <a href='/pf'><Card className={classes.imageBg} style={{ backgroundImage: `url(${Pf})` }}></Card></a> */}
         {/* </Box> */}

         <Box px={4} className={classes.boxWrapper} style={{ width: 240 }}>
            <a href='/dease'><Card className={classes.imageBg} style={{ backgroundImage: `url(${Dease})` }}></Card></a>
         </Box>

         <Box px={4} className={classes.boxWrapper} style={{ width: 240 }}>
            {/* <a href='/navegantes'> */}
               <Card className={classes.imageBg} style={{ backgroundImage: `url(${Navegantes})` }}></Card>
            {/* </a> */}
         </Box>

      </Slider>
   );
}