import React from 'react';
import { makeStyles, Button, MenuItem, Fade, Menu } from '@material-ui/core';
import { animateScroll as scroll } from "react-scroll"; //SMOOTH SCROLL

//ICONS
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  hambMenu: {
    color: theme.palette.primary.dark,
    fontSize: '2rem',
    marginTop: '-5px',
  },
  menuItem: {
    padding: '.5rem 1.5rem',
    fontSize: '1rem',
    lineHeight: '2rem',
    fontFamily: 'Inter, sans-serif',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:focus, &:hover': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'rgba(0,0,0,0.7)',
      '&:focus, &:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
      },
    },
  },
  icons: {
    //paddingRight: theme.spacing(0),
    color: 'white',
    fontSize: '1rem',
    paddingLeft: '.6rem',
    paddingRight: '.6rem',
    [theme.breakpoints.down('sm')]: {
      color: 'rgba(0,0,0,0.6)',
    },
  },
}));


export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  // eslint-disable-next-line
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon className={classes.hambMenu} />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.MenuMobile}
      >
        <MenuItem onClick={handleClose}>
          <a className={classes.menuItem} href='/' rel="noreferrer">
            Home
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a className={classes.menuItem} href='https://ead.rbcarreiraspoliciais.com.br' rel="noreferrer">
            Portal do Aluno
          </a>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <Link
            className={classes.menuItem}
            href='/' to='consorcio'
            smooth={true} spy={true} duration={700}>
            Home
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className={classes.menuItem}
            href='https://ead.rbcarreiraspoliciais.com.br/' to='parceiros'
            smooth={true} spy={true} duration={700}>
            Area do aluno
          </Link>
        </MenuItem> */}
      </Menu>
    </div>
  );
}
